import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const smooth = "transition-all ease-in-out duration-300";

export default function ResponsePopUp({ title, titleIns, titleInsEng, image }) {
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const pathToImage = getImage(image);
  return (
    <>
      <div className="inset-0 flex items-center justify-center w-full">
        <button
          onClick={openModal}
          className={`${smooth} hover:bg-blue-710 font-lufga300 italic bg-stone-900 w-[75%] h-12 rounded-lg text-white mt-2`}
        >
          {title}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="transition-all duration-300 min-h-screen bg-stone-900 bg-opacity-30 backdrop-blur-md px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block max-h-[90vh] max-w-[90vw] lg:max-w-[60vw] p-6 my-8 overflow-y-scroll no-scrollbar text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="mb-6">
                  <button
                    type="button"
                    className="transition-all duration-300 font-lufga400 inline-flex justify-center px-4 py-2 text-sm font-medium text-stone-600 hover:text-white bg-stone-100 border border-transparent rounded-md hover:bg-blue-710 "
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className="flex flex-col justify-start items-start text-lg lg:text-xl font-headlao leading-6 text-gray-900 w-full h-full"
                >
                  <div className="font-runegifter text-5xl  ">{titleIns}</div>
                  {/* <div className="font-bodoni font-semibold text-lg lg:text-xl border-t-2 border-stone-300 pt-2 mt-2">
                    {titleInsEng}
                  </div> */}
                </Dialog.Title>
                {/* image */}
                <div className="my-4 flex justify-center items-center">
                  <GatsbyImage
                    image={pathToImage}
                    alt={titleIns}
                    className={`${smooth} object-contain h-full max-w-[500px]`}
                    imgClassName={`${smooth} object-contain`}
                  />
                </div>
                {/* image */}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
